import React, { useEffect, useState } from "react"
import { useSpring, animated } from "react-spring"
import useResizeObserver from "use-resize-observer"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

const XRay = ({ image, contain = false, onActive, onOpen }) => {
  const [xrayRef, width, height] = useResizeObserver()
  const [active, setActive] = useState(false)
  const [hover, setHover] = useState(false)

  const maskBaseSize = Math.min(width, height) * 0.4
  const maskScale = 1.6
  const [maskSize, setMaskSize] = useState(maskBaseSize)
  const [[x, y], setPosition] = useState([
    width / 2 - maskSize / 2,
    height / 2 - maskSize / 2
  ])

  useEffect(() => {
    disableBodyScroll(xrayRef.current)

    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (!active) {
      setMaskSize(hover ? maskBaseSize * maskScale : maskBaseSize)
      setPosition([width / 2 - maskSize / 2, height / 2 - maskSize / 2])
    }
  }, [hover, width, height, maskSize])

  const handleStart = event => {
    event.preventDefault()

    if (!active) {
      setHover(true)
    }
  }

  const handleEnd = event => {
    event.preventDefault()

    if (!active) {
      setHover(false)
    }
  }

  const handleMove = event => {
    event.preventDefault()

    if (!active) {
      const bounds = event.target.getBoundingClientRect()
      const x =
        event.type === "touchmove" ? event.touches[0].pageX : event.clientX
      const y =
        event.type === "touchmove" ? event.touches[0].pageY : event.clientY

      setMaskSize(maskBaseSize * maskScale)
      setPosition([
        x - bounds.left - (maskBaseSize * maskScale) / 2,
        y - bounds.top - (maskBaseSize * maskScale) / 2
      ])
    }
  }

  const handleClick = event => {
    event.preventDefault()

    const tempMaskSize = Math.max(width, height) * 1.6

    if (onActive) onActive()
    setActive(true)
    setMaskSize(tempMaskSize)
    setPosition([width / 2 - tempMaskSize / 2, height / 2 - tempMaskSize / 2])

    setTimeout(() => {
      if (onOpen) onOpen()
      clearAllBodyScrollLocks()
    }, 1000)
  }

  const animatedMask = useSpring({
    WebkitMaskPosition: `${x}px ${y}px`,
    maskPosition: `${x}px ${y}px`,
    WebkitMaskSize: `${maskSize}px`,
    maskSize: `${maskSize}px`
  })

  return (
    <animated.div
      ref={xrayRef}
      onMouseEnter={handleStart}
      onMouseMove={handleMove}
      onMouseLeave={handleEnd}
      onClick={handleClick}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={event => {
        handleEnd(event)
        handleClick(event)
      }}
      className="xray__image"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: contain ? "contain !important" : "cover",
        ...animatedMask
      }}
    />
  )
}

export default XRay
