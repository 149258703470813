import React, { useState } from "react"
import { graphql } from "gatsby"
import XRay from "../components/XRay"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import Panel from "../components/Panel"
import PanelContent from "../components/PanelContent"

export const WorkTemplate = ({
  image,
  contain = false,
  loupe = false,
  cartel: { title, subtitle, description, description_secondary },
  panel: { title: panelTitle, text, text_secondary, videos, links }
}) => {
  const [active, setActive] = useState(!loupe)
  const [open, setOpen] = useState(!loupe)
  const [toggle, setToggle] = useState(false)
  const src = !!image.childImageSharp ? image.childImageSharp.fluid.src : image

  const handleActive = () => setActive(true)
  const handleOpen = () => setOpen(true)

  const handleClick = () => setToggle(!toggle)

  return (
    <div
      className={`xray ${active ? "xray--active" : ""} ${
        open ? "xray--open" : ""
      }`}
    >
      <div
        className="xray__backdrop"
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: contain ? "contain !important" : "cover"
        }}
      >
        <div className="cartel">
          <div className="cartel__title">
            <h3>{title}</h3>
            <h4>{subtitle}</h4>
            <ReactMarkdown
              source={description_secondary}
              renderers={{
                link: props => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.children}
                  </a>
                )
              }}
            />
            <ReactMarkdown
              source={description}
              renderers={{
                link: props => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.children}
                  </a>
                )
              }}
            />
          </div>
        </div>
        <div className="xray__panel-clicker" onClick={handleClick} />
      </div>
      <Panel toggle={toggle}>
        <PanelContent
          title={panelTitle}
          text={text}
          text_secondary={text_secondary}
          videos={videos}
          links={links}
        />
      </Panel>
      {open ? null : (
        <XRay
          image={src}
          contain={contain}
          onActive={handleActive}
          onOpen={handleOpen}
        />
      )}
    </div>
  )
}

const Work = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout className="work">
      <WorkTemplate
        image={frontmatter.image}
        contain={frontmatter.contain}
        loupe={frontmatter.loupe}
        cartel={frontmatter.cartel}
        panel={frontmatter.panel}
      />
    </Layout>
  )
}

export default Work

export const pageQuery = graphql`
  query WorkTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "work" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contain
        loupe
        cartel {
          title
          subtitle
          description
          description_secondary
        }
        panel {
          title
          text
          text_secondary
          videos {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
            title
            subtitle
            duration
          }
          links {
            label
            label_secondary
            link
          }
        }
      }
    }
  }
`
